<template>
  <div>
    <CModal
        title="New Sales Region"
        size="xl"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <SalesRegionForm
          v-bind:sales-region-in="salesRegion"
          v-bind:vendor-nid="this.$route.params.vendor_nid"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import SalesRegionForm from "@/apps/partners/components/SalesRegionForm";
const clonedeep = require('lodash.clonedeep')

const createSalesRegion = gql`
  mutation createSalesRegion($salesRegion: SalesRegionInput){
    createSalesRegion(salesRegion: $salesRegion) {
      nid
      name
      vendor {
        nid
        shortName
        operatesIn {
          nid
          name
        }
      }
      activePapp
    }
  }
`

export default {
  name: "SalesRegionAdd",
  components: {
    SalesRegionForm
  },
  data(){
    return {
      salesRegion: {
        activePapp: false
      },
      salesRegionSave: {},
      error: null,
      salesRegionTemplate: {
        activePapp: false
      }
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.salesRegionSave = data;
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createSalesRegion,
          variables: {
            salesRegion: this.salesRegionSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createSalesRegion);
          this.salesRegion = clonedeep(this.salesRegionTemplate);
          this.salesRegionSave = clonedeep(this.salesRegionTemplate);
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.salesRegion = clonedeep(this.salesRegionTemplate);
        this.salesRegionSave = clonedeep(this.salesRegionTemplate);
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
